<template>
  <!-- <div class="body-color"> -->
  <div
    align="center"
    :class="{
      'ma-0': $vuetify.breakpoint.smAndDown,
      'ma-5': $vuetify.breakpoint.mdAndUp,
    }"
    xs12
    sm6
    md4
  >
    <notification-popup
      :dialog="notifFailed"
      :contentMsg="contentMsg"
      :headerMsg="$t('Failed')"
      :nameBtnYes="$t('labelYes')"
      :nameBtnNo="$t('labelNo')"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerNo="cancelFailed"
      disableBtnNo="true"
      :onHandlerYes="buttonOkeFailed"
    />
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('verifySuccess')"
      :headerMsg="$t('succesfully')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelOk')"
      colorIcon="success"
      typeModal="transaction"
      Icon="mdi-check-circle"
      disableBtnNo="true"
      :onHandlerYes="buttonOke"
    />
    <div class="body-login">
      <div align="left" v-if="initialPath != undefined ? initialPath.login == '/login-v2' : false">
        <p class="title-login" style="color: #000;">{{ $t("AccountVerification") }}</p>
        <h3 class="h3-title" style="color: #000;">{{ $t("ThankYouForYourRegistration") }}</h3>
      </div>
      <div align="left" v-else>
        <p class="title-login">{{ $t("AccountVerification") }}</p>
        <h3 class="h3-title">{{ $t("ThankYouForYourRegistration") }}</h3>
      </div>
      <v-row align="center">
        <!-- <v-col cols="2" v-for="(v,i) in this.otpLength" :key="i"> -->
        <v-text-field
          required
          :color="$partnerACL.getButtonColor()"
          class="centered-input"
          v-model="otpValue"
          minlength="6"
          :placeholder="$t('inputOTP')"
          maxlength="6"
        ></v-text-field>
        <!-- </v-col> -->
      </v-row>
      <v-row>
        <v-col>
          <p align="center">
            {{ $t("Haventreceivedaconfirmationemail") }}

            ( {{ minutes }} : {{ second }} )
          </p>
          <v-btn
            text
            class="label-cont text-capitalize"
            :color="$partnerACL.getButtonColor()"
            :disabled="!after"
            @click="resendOTP('button')"
          >
            {{ $t("Resend") }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- <p>otp={{this.otpValue}}</p>
        <p>id={{this.customerUserID}}</p>
        <p>email={{this.otpEmail}}</p> -->
      <div align="center" class="footer">
        <v-btn
          class="text-capitalize white--text"
          rounded
          :color="$partnerACL.getButtonColor()"
          :disabled="otpValue == '' || otpValue.length < 6"
          @click="verifyOTP"
          >{{ $t("Confirmation") }}</v-btn
        >
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
// import AcButton from "@/components/AcButton";
import NotificationPopup from "@/components/NotificationPopup.vue";
const ON_INPUT_COMPLETE_EVENT = "onComplete";
const ON_INPUT_CHANGE_EVENT = "onChange";
export default {
  components: { NotificationPopup },
  data() {
    return {
      notifFailed: false,
      notifSuccess: false,
      customerUserID: this.$route.params.data.customer_user_id,
      otpEmail: this.$route.params.data.email,
      //       customerUserID:"a",
      // otpEmail:"b",
      minutes: 0.5,
      second: 0,
      countDown: 0,
      otpLength: 6,
      otp: [],
      otpValue: "",
      contentMsg: "",
      isComplete: false,
    };
  },
  mounted() {
    if (this.customerUserID == undefined) {
      this.$router.go(-1);
    }else if(this.$route.params != undefined){
      this.resendOTP('mounted')      
    }
    // console.log("this.$store.state.auth.auth_inactive_payload_temp",this.$store.state.auth.auth_inactive_payload_temp)
  },
  computed: {
    after() {
      return this.countDown == 0;
    },
    landingPage() {
      return this.$partnerACL.getACLConfiguration().landing_page;
    },
    initialPath() {
      return this.$store.state.auth.initialPath
    }
  },
  beforeMount() {
    this.countDown = 60 * this.minutes + this.second;
    this.countDownTimer();
  },
  methods: {
    cancelFailed() {
      this.notifFailed = false;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.minutes = Math.floor(this.countDown / 60);
          this.second = this.countDown - this.minutes * 60;
          this.countDownTimer();
        }, 1000);
      }
    },
    register() {
      this.$router.push({ name: "Register" });
    },
    resendOTP(param) {
      const payload = {
        customer_user_id: this.customerUserID,
        email: this.otpEmail,
        otp_type: "REG",
        otp_language : this.$i18n.locale
      };
      this.$store
        .dispatch("auth/resendOTP", payload)
        .then(() => {
          if (param == 'button') {
            this.minutes = 0.5
            this.second = 0
            this.countDown = 60 * this.minutes + this.second;
            this.countDownTimer();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verifyOTP() {
      const payload = {
        customer_user_id: this.customerUserID,
        otp_value: this.otpValue,
      };
      this.$store
        .dispatch("auth/otpVerify", payload)
        .then((response) => {
          if (response.status) {
            // this.$store.dispatch("auth/logout").then(() => {
            //   this.notifSuccess = true;
            // });
            // auto login
            const loginPayload = {
              ...this.$store.state.auth.auth_inactive_payload_temp
            };
            this.$store
              .dispatch("auth/login", loginPayload)
              .then((response) => {
              this.$router.push({ name: this.landingPage ? this.landingPage.menu_name : "Home" });
              if (response.status) {
                this.$router.push({ name: this.landingPage ? this.landingPage.menu_name : "Home" });
              } else {
                this.notifFailed = true;
                this.contentMsg = this.$t(response.message);
              }
            })
            .catch((err) => {
              console.log(err);
              // this.notifFailed = true;
              // this.contentMsg = this.$t(err.response.data.message);
            });
          } else {
            this.notifFailed = true;
            this.contentMsg = this.$t(response.message);
          }
        })
        .catch((err) => {
          // this.notifFailed = true;
          // this.contentMsg = this.$t(err.response.data.message);
          console.log(err);
        });
    },
    handleInput(e, i) {
      this.$nextTick(() => {
        this.isComplete = false;
        if (e.keyCode === 8) {
          this.otp = this.otp.map((item, idx) => {
            if (idx >= i) {
              item = null;
            }
            return item;
          });
          if (i > 0) {
            this.$refs["input" + (i - 1)][0].focus();
          }
        } else {
          this.otp = this.otp.map((item, idx) => {
            if (idx === i) {
              item = e.key;
            }
            return item;
          });
          if (i < this.otpLength - 1) {
            this.$refs["input" + (i + 1)][0].focus();
          } else {
            this.isComplete = true;
          }
        }
        const dataChange = {
          index: i,
          value: this.otp[i],
        };
        this.$emit(ON_INPUT_COMPLETE_EVENT, dataChange);
      });
    },
    handleFocus(e, i) {
      e.target.select();
      if (!this.otp[i]) {
        const c = this.otp.filter((item) => item).length;
        this.$refs["input" + c][0].focus();
      }
    },
    buttonOkeFailed() {
      this.notifFailed = false;
      if (this.notifFailed == false) {
        this.minutes = 5;
        this.second = 0;
        this.countDown = 60 * this.minutes + this.second;
        this.countDownTimer();
      }
    },
    buttonOke() {
      this.notifSuccess = false;
      try {
        let loginUrl = this.$store.state.auth.initialPath.login
        this.$router.push({
          path: loginUrl,
          params: { fromWhere: 'validation' }
        });
      } catch (error) {
        this.$router.push({ path: '/login', params: { fromWhere: 'validation' } })
      }
    },
  },
  watch: {
    isComplete() {
      if (this.isComplete) {
        // console.log("complete:"+this.isComplete); //keyCodes value
        // console.log("complete:"+(this.otp).join("")); //keyCodes value
        this.otpValue = this.otp.join("");
        // console.log("complete:"+ this.otpValue); //keyCodes value
        this.$emit(ON_INPUT_CHANGE_EVENT, [...this.otp]);
        this.isComplete = false;
      }
    },
  },
};
</script>

<style scoped>
.body-color {
  background-color: #f5fdf7;
  height: 100%;
  width: 100%;
}

.centered-input >>> input {
  text-align: center;
}
.body-login {
  /* position: fixed; */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.h3-title {
  color: #4bb14e;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 1rem;
  margin-top: -1rem;
}
.title-login {
  color: #4bb14e;
  font-size: 16px;
  line-height: 19px;
  margin-top: 1rem;
}
.label-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.label-cont {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 136%;
  /* or 16px */

  color: #4bb14e;
}

.label-rememberme {
  color: #2cd196;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.warning-username {
  margin-top: -2rem;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.btn-login {
  color: #4bb14e;
  background-color: #f5f5f5;
  margin-right: 5%;
  height: 45px;
  width: 156px;
}

.btn-signup {
  color: #4bb14e;
  background-color: #f5f5f5;
  height: 45px;
  width: 90%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>